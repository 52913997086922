import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Local
import EmbedPhoto from './EmbedPhoto'
import EmbedPodcast from './EmbedPodcast'
import EmbedRich from './EmbedRich'
import EmbedVideo from './EmbedVideo'

const Embed = (props) => {
  // Main Embed handler for the Embed field type
  switch (props.embed.type) {
    case 'photo':
      return (
        <EmbedPhoto {...props} />
      )

    case 'rich':
      return (
        <EmbedRich {...props} />
      )

    case 'video':
      return (
        <EmbedVideo {...props} />
      )

    case 'link':
      return (
        <EmbedPodcast {...props} />
      )

    default:
      throw new Error('No embed renderer found for following type ', props.embed.type)
  }
}

Embed.propTypes = {
  embed: PropTypes.object.isRequired,
  thumbnail: PropTypes.object,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  title: PropTypes.string,
}

export default Embed

export const query = graphql`
  fragment Embed on PrismicEmbedType {
    height
    html
    provider_name
    thumbnail_url
    title
    type
    url
    width
    embed_url
  }
`
